@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&display=swap");
:root {
  --background-color: rgb(246, 246, 249);
  --text-color: rgb(49, 29, 61);
  --accent-color: rgb(201, 216, 43);
  --high-header-height: 114px;
  --low-header-height: 50px;
  --limited-width: 1200px;
  --whole-menu-height: 300px;
  --purple-text-color: rgb(81, 0, 255);
}
html {
  hanging-punctuation: first last;
}
#root {
  display: flex;
  flex-direction: column;
  align-items: center;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}
a {
  color: inherit;
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-family: Quicksand, sans-serif;
}
h1 {
  font-size: clamp(2rem, 1rem + 10vw, 5rem);
}
.full-screen-heading {
  color: white;
  text-align: center;
  line-height: 100vh;
  text-shadow: 0 0 5px black;
}
.half-screen-heading {
  color: white;
  text-align: center;
  line-height: 50vh;
  text-shadow: 0 0 5px black;
}
.shader {
  width: 100%;
  background-color: transparent;
  background-image: linear-gradient(135deg, rgba(11, 33, 43, 0.7) 0%, rgba(29, 57, 75, 0.7) 100%);
}
.no-wrap {
  white-space: nowrap;
}
.designed-button {
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
  color: var(--text-color);
  background-color: var(--accent-color);
  padding: 1rem 2rem;
  margin-top: 3rem;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.designed-button-icon {
  fill: black;
  width: 1.5rem;
}
.padding-left-1rem {
  padding-left: 1rem;
}
*::placeholder {
  font-family: 'Poppins', Arial, sans-serif;
}