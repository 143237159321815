.team-page-container {
  position: relative;
}
.parallax--background {
  top: -10vh;
  z-index: -2;
  min-height: 120svh;
  animation-name: parallax-background;
  animation-timeline: scroll();
}
.parallax--object {
  top: 20vh;
  z-index: -1;
  animation-name: parallax-object;
  animation-timeline: scroll();
}
.parallax--background, .parallax--object {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  min-width: 100svw;
  text-align: center;
}
.team-page-content {
  padding: 1rem;
}
.team-member {
  max-width: var(--limited-width);
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 1rem;
  gap: 3rem;
  padding-bottom: 2rem;
}
.team-member-picture {
  border: 5px solid white;
  width: 400px;
  border-radius: 10px;
  box-shadow: 0 0 5px black;
}
.team-member-description {
  background-color: white;
  border-radius: 10px;
  /* min-height: 100%; */
  padding: 2rem;
  box-shadow: 0 0 5px black;
}
.team-member-description-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* gap: 5rem; */
  font-size: 1.5rem;
}
.team-member-description-picture {
  display: none;
}
.team-member-description-links {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.team-member-description-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  height: 2rem;
  width: 2rem;
  background-color: var(--accent-color);
  border-radius: 50%;
}
.team-member-description-links svg {
  fill: black;
  width: 1rem;
  height: 1rem;
}
@media screen and (max-width: 899px) {
  .team-member-picture {
    display: none;
  }
  .team-member-description {
    min-height: 100%;
  }
  .team-member-description-picture {
    display: block;
    width: 10rem;
    border-radius: 50%;
  }
}
@keyframes parallax-background {
  from {
    top: 0;
  }
  to {
    top: -10%;
  }
}
@keyframes parallax-object {
  from {
    top: 20vh;
  }
  25% {
    top: 35vh;
  }
  50% {
    top: 25vh;
  }
  75% {
    top: 35vh;
  }
  to {
    top: 25vh;
  }
}