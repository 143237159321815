.footer {
  width: 100%;
  height: auto;
  padding: 3rem;
  /* margin-top: 6rem; */
  color: white;
  background-color: rgb(17, 32, 42);
  font-family: Quicksand, sans-serif;
}
.footer-container {
  width: 90%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.footer-container > * {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 1rem;
}
.left-footer {
    align-items: center;
}
.right-footer {
    align-items: flex-start;
}
.social-icons {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 1rem;
}
.social-icons a {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--accent-color);
  border-radius: 50%;
}
.social-icons svg {
  fill: var(--accent-color);
  width: 1rem;
  height: 1rem;
}
.footer-links {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
}
.svg-container {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  border-radius: 50%;
}
.svg-container svg {
  width: 1rem;
  fill: white;
}
.bottom-footer {
  width: 100%;
  height: auto;
  color: white;
  background-color: rgb(17, 32, 42);
  font-family: Quicksand, sans-serif;
  border-top: 1px solid rgb(46, 46, 46);
  line-height: 3rem;
  text-align: center;
}
@media screen and (max-width: 899px) {
  .footer-container {
    flex-direction: column;
  }
}
