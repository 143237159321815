.price-page-container {
  /* position: relative; */
}
.enlarging--background {
position: fixed;
z-index: -2;
top: 50%;
left: 50%;
overflow: hidden;
transform: translate(-50%, -50%);
min-width: 100%;
min-height: 100%;
animation: background-enlarge;
animation-timeline: scroll();
}
.price-page-content {
  max-width: var(--limited-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 2rem;
}
.price-page-content > h2 {
line-height: 6rem;
color: white;
text-shadow: 0 0 5px black;
}
.price-page-choises {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 1rem;
  width: 100%;
  /* max-width: 1200px; */
  /* margin: auto; */
}
.price-page-choises > * {
    width: 100%;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}
.price-page-choises hr {
    width: 100%;
    border: 1px solid gray;
}
.price-page-choises ul li {
    line-height: 2rem;
}
.checkmark-bullet::before {
content: "\2713";
color: green;
display: inline-block;
font-weight: 700;
font-size: 1.5rem;
margin-right: 10px;
}
.cross-bullet::before {
content: "\2717";
color: red;
display: inline-block;
font-weight: 700;
font-size: 1.2rem;
margin-right: 10px;
}
.price-page-icon {
  width: 3rem;
  height: 3rem;
  border: 2px solid grey;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.price-page-icon svg {
  width: 2rem;
  fill: grey;
}
.standart-choise {
  background-color: #f7e2cf;
}
.premium-choise {
  background-color: #d6f8bc;
}
@media screen and (max-width: 899px) {
  .price-page-choises {
    flex-direction: column;
  }
}
@keyframes background-enlarge {
  from {
    min-width: 100%;
    min-height: 100%;
  }
  to {
    min-width: 150%;
    min-height: 150%;
  }
}