.home-page-container {
  /* width: 100%; */
  /* font-family: Quicksand, sans-serif; */
}
.home-page-title-container {
  /* width: 100%; */
  /* background: url("/img/037_WEB_Deivis_ir_Marius.webp") no-repeat center/cover; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.home-page-title {
  max-width: 1200px;
  height: 100svh;
  margin: 0 auto;
  color: var(--background-color);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-shadow: var(--text-color) 1px 0 10px;
}
.home-page-title h1 {
  text-transform: uppercase;
}
.home-page-title p {
  margin: 0;
  /* padding: 0; */
  font-weight: 500;
  font-size: 1.5em;
}
.centered-title {
  color: var(--background-color);
  text-shadow: var(--text-color) 1px 0 10px;
  padding-top: 5rem;
  padding-bottom: 2rem;
  text-align: center;
  font-size: 3rem;
}
.home-page-services-choises {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 90%;
  max-width: 1200px;
  margin: auto;
}
.home-page-services-choises * {
  text-align: center;
  width: 100%;
  height: 15rem;
  border-radius: 5px;
  box-shadow: 0 0 10px 2px var(--background-color);
}
.shadow {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
  background-color: rgba(9, 3, 53, 0.5);
  transition: background 1s, color 1s;
}
.shadow:hover {
  color: black;
  background-color: rgba(9, 3, 53, 0);
}
#administration {
  background: center /cover no-repeat url('../../../public/img/hotel-linens-1-300x200.webp');
}
#consultations {
  background: center /cover no-repeat url('../../../public/img/consulting.jpg');
}
.home-page-feedback-container {
  padding-bottom: 6rem;
}
.home-page-feedback {
  width: 90%;
  max-width: 1200px;
  margin: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.home-page-feedback > * {
width: 20rem;
height: 20rem;
border-radius: 5px;
box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.15);
padding: 2rem;
font-size: 1rem;
line-height: 1.5rem;
background-color: var(--background-color);
}
.feedback-picture {
  padding-top: 1.5rem;
  max-width: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1rem;
  font-weight: 700;
  gap: 1rem;
}
.feedback-picture img {
  max-width: 100%;
  height: auto;
}
@media screen and (max-width: 899px) {
  .home-page-container {
    background-size: contain;
    height: auto;
  }
  .home-page-title {
    padding-top: var(--high-header-height);
    width: 100svw;
    max-height: 80svh;
  }
  .home-page-container {
    background-size: 100 auto;
  }
  .home-page-services-choises {
    flex-direction: column;
  }
  .home-page-feedback {
    flex-direction: column;
  }
  .home-page-feedback > * {
    width: 100%;
  }
}
