.services--background {
  position: fixed;
  z-index: -2;
  top: 0;
  left: 0;
  overflow: hidden;
  min-width: 100%;
  min-height: 100%;
  animation: background-enlarge;
  animation-timeline: scroll();
}
.service-page-choises {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  grid-auto-rows: minmax(auto, 100%);
}

@media screen and (max-width: 899px) {
  .service-page-choises {
    grid-template-columns: repeat(1, 1fr);
  }
}
