.contacts-page-container {
  /* position: relative; */
  /* background: url("/img/contacts-neon.png") no-repeat center/cover; */
}
.moving--background {
  position: absolute;
  z-index: -2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
  overflow: clip;
  animation: background-enlarge;
  animation-timeline: scroll();
}
.contacts-page-content {
  max-width: var(--limited-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding-bottom: 6rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.contacts-page-content > h2 {
  line-height: 6rem;
  color: white;
  text-shadow: 0 0 5px black;
}
.contacts-page-modules {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 1rem;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 2rem;
  background-color: white;
  border-radius: 15px;
}
.contacts-page-modules > * {
  /* flex-grow: 1; */
  width: 100%;
  /* padding: 1rem; */
  /* border-radius: 5px; */
  /* box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3); */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.contact-page-form {
  padding: 2rem;
  background-color: rgb(17, 32, 42);
  color: var(--background-color);
  border-radius: 10px;
}
.contact-page-form form {
  color: white;
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
}
.contact-page-form input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 0.5rem;
  /* margin: 0.5rem; */
  /* line-height: 2rem; */
}
.contacts-page-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* flex-wrap: nowrap; */
  width: 100%;
}
.contacts-page-icon {
  width: 50px;
  height: 50px;
  border: 2px solid black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contacts-page-link svg {
  height: 1rem;
  fill: black;
}
.contacts-page-contacts-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 2rem;
}
.contacts-page-contacts-list > * {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contacts-page-member-picture {
  width: 5rem;
  border-radius: 50%;
}
.contacts-page-team-member h2 {
  padding: 1rem;
  white-space: nowrap;
}
@media screen and (max-width: 899px) {
  .contacts-page-modules {
    flex-direction: column;
  }
}
@media screen and (max-width: 599px) {
  .contacts-page-contacts-list {
    flex-direction: column;
  }
  .contacts-page-link {
    flex-direction: column;
    gap: 1rem;
  }
}