.header-container {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  color: white;
  height: var(--high-header-height);
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;
}
.header-container.active-header {
  background-color: rgba(255, 255, 255, 1);
  height: var(--low-header-height);
  color: black;
}
.logo-container {
  position: absolute;
  z-index: 5;
  top: 0;
  height: 100%;
  width: 100%;
  max-width: 1200px;
  padding: 0.3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.padding1 {
  padding: 1rem;
}
.title {
  height: 100%;
}
.logo-container img {
  height: 100%;
}
.desktop-menu-list {
  position: relative;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.desktop-list-item {
  /* color: white; */
  display: inline-block;
  box-sizing: border-box;
  padding: 1rem;
  font-size: 1rem;
}
.desktop-list-item:hover {
  color: var(--accent-color);
}
.mobile-menu-list {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.4s ease;
}
.mobile-list-item {
  color: black;
  border: 1px solid rgb(234, 234, 234);
  background-color: var(--background-color);
  display: inline-block;
  box-sizing: border-box;
  padding: 1rem;
  font-size: 1rem;
}
.mobile-list-item:hover {
  color: var(--accent-color);
}
.active {
  color: var(--accent-color);
}
@media screen and (max-width: 899px) {
  .header-container {
    background-color: var(--background-color);
  }
  .logo-container {
    background-color: var(--background-color);
  }
  .active-header .mobile-menu-list.visible {
    top: var(--low-header-height);
  }
  .inactive-header .mobile-menu-list.visible {
    top: var(--high-header-height);
  }
  .active-header .mobile-menu-list.invisible {
    top: calc(var(--low-header-height) - var(--whole-menu-height));
  }
  .inactive-header .mobile-menu-list.invisible {
    top: calc(var(--high-header-height) - var(--whole-menu-height));
  }
  .list-item {
    color: white;
    border: 1px solid rgb(234, 234, 234);
  }
  .hamburger {
    cursor: pointer;
    display: block;
    z-index: 3;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--accent-color);
    border-radius: 5px;
  }
  .hamburger-stroke {
    position: relative;
    flex: none;
    width: 40%;
    height: 3px;
    background: black;
    transition: all 0.4s ease;
  }
  .hamburger-stroke::before,
  .hamburger-stroke::after {
    content: "";
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 3px;
    background: inherit;
  }
  .hamburger-stroke::after {
    top: 6px;
  }
  .hamburger-stroke::before {
    top: -6px;
  }
  .active .hamburger-stroke {
    transform: rotate(135deg);
  }
  .active .hamburger-stroke:before,
  .active .hamburger-stroke:after {
    top: 0;
    transform: rotate(270deg);
  }
}
