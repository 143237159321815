.service-choise {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3);
  background-color: #d6f8bc;
}
.service-choise-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  gap: 1rem;
}
.service-choise-title {
font-weight: 600;
text-align: center;
}
.service-page-icon {
  width: 3rem;
  height: 3rem;
  border: 2px solid black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.service-page-icon svg {
  width: 1.5rem;
  fill: black;
}
.reddish-button {
  /* color: blue; */
  /* font-weight: 600; */
}
.service-page-description {
  /* text-align: justify; */
}